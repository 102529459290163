import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import { withBottombar } from "../../HOCs";
import { DeleteAssetIcon } from "../../assets/deleteAsset";
import { UploadAssetIcon } from "../../assets/uploadAsset";
import { Upload } from "../../components/upload/fileupload";
import { AlertContext, BackdropContext } from "../../contexts";
import { AlertProps, allowed_file_size, generateImageUrl, img_size, multiFileUpload } from "../../utils";
import { FontFamilySwitch } from '../../utils/common';
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./style";
import { DialogDrawer } from "../../components";
import VideocamIcon from '@mui/icons-material/Videocam';
import { CamRecorder } from "./camRecorder";
import { ReplayVideo } from "./replayVideo";
import { stopAudioRecorder, stopCamrecorder } from "./utils";
import AudioRecorder from "./audioRecorder";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import MicIcon from '@mui/icons-material/Mic';
import { ReplayAudio } from "./replayAudio";
import { VideoCard } from "./videoCard";

const useUnload = (fn) => {
  const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined
  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = (...args) => cb.current?.(...args);

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);
};

const Step3 = (props) => {
  const { t = () => false, handleTopBarState } = props
  const classes = useStyles();
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  // const [uploaddocc, setuploaddocc] = React.useState(
  //   props?.request?.selectedImages ? props.request.selectedImages : []
  // );
  const [isRecordOptionDialogOpen, setIsRecordOptionDialogOpen] = useState(false);
  const [isCameraDialogOpen, setIsCameraDialogOpen] = useState(false);
  const videoRef = useRef(null);
  const videoRecorderRef = useRef(null);
  const [isVideoRecording, setIsVideoRecording] = useState(false);
  const [videoBlob, setVideoBlob] = useState(null);
  const [isReplayVideoDialogOpen, setIsReplayVideoDialogOpen] = useState(false);
  const [isAudioRecorderDialogOpen, setIsAudioRecorderDialogOpen] = useState(false);
  const audioRef = useRef(null);
  const audioRecorderRef = useRef(null);
  const [isAudioRecording, setIsAudioRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [isReplayAudioDialogOpen, setIsReplayAudioDialogOpen] = useState(false);


  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });
  const uploadImageS3 = async (file, file_size = allowed_file_size,id) => {
    try {
      let propsData = { companyId: props?.request?.unit?.property?.company?.value, file_meta: true }
      let multipleImageId=Array.from(file)?.map((x,index)=>{return x?.lastModified})
      const Images = await multiFileUpload(file, propsData, alert, file_size)
      const updatedImages = props?.request?.selectedImages?.map((x) => {
        return {
          ...x,
          is_loading: x?.is_loading?(file.length===1?(x?.id === id ? false : true):(multipleImageId.includes(x?.id) ? false : true)):x?.is_loading
        }
      })
      if (Images?.length > 0) {
        props.updateFunction(Images)
        props?.setRequest({
          ...props?.request,
          selectedImages: updatedImages,
          uploadedImages: [...props?.request?.uploadedImages, ...Images]
        })
      }
    }
    catch (err) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("SomethingWentwrong"),
      });
    }

  }

  const handleUpload = async (e) => {
    if (e.target.files && props?.request?.selectedImages?.length < 5) {
      backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
      let files = e.target.files;
      const filesArray = Array.from(e.target.files).map((val, i) => {
        return {
          id:files?.[i]?.lastModified,
          url: URL.createObjectURL(val),
          is_active: true,
          is_loading: true,
          file_meta: {
            name: files?.[i]?.name,
            size: files?.[i]?.size,
            type: files[i]?.type
          }
        }
      });
      if (filesArray?.length <= 5 && props.request.selectedImages.concat(filesArray)?.length <= 5) {
        props.request.selectedImages = props.request.selectedImages.concat(filesArray);
        uploadImageS3(files,allowed_file_size,files?.[0]?.lastModified)

        props.setRequest(props.request);
        backdrop.setBackDrop({ ...backdrop, open: false, message: "Loading..." })
        Array.from(e.target.files).map(
          (file) => URL.revokeObjectURL(file) // avoid memory leak
        );
      }
      else{
        backdrop.setBackDrop({ ...backdrop, open: false, message: "Loading..." })
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("You can't upload more than 5 media"),
        });
      }
    }
    else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("5 / 5 media are Uploaded , limit reached"),
      });
    }
  };
  // const handleDelete = (item) => {
  //   // props.request.selectedImages = props.request.selectedImages?.filter((index) => index !== i)
  //   // props.request.uploadedImages = props.request.uploadedImages?.filter((index) => index !== i)
  //   // props.setRequest(props.request);
  //   // setuploaddocc(uploaddocc?.filter(( index) => index !== i));
  //   props?.handleDelete(item) 
  //   // setuploaddocc(uploaddocc?.filter((item,index) => index !== i))
  // };
  // const onRemoveAll = () => {
  //   // props.request.selectedImages = [];
  //   // props.request.uploadedImages = [];
  //   // props.setRequest(props.request);
  //   props?.onRemoveAll()
  //   // setuploaddocc([]);
  // };

  React.useEffect(() => {
    handleTopBarState({
      text: t("New Maintenance Request"),
      customBackHandle: () => props?.goBack()
    })
    // eslint-disable-next-line
  }, []);

  const handleCameraDialogOpen = () => {
    setIsCameraDialogOpen(true);
    setIsRecordOptionDialogOpen(false);
  }

  const handleCameraDialogClose = () => {
    setIsCameraDialogOpen(false);
    setIsVideoRecording(false);
    stopCamrecorder({ videoRef, mediaRecorderRef: videoRecorderRef });
  }

  const handleReplayVideoDialogClose = () => {
    setIsReplayVideoDialogOpen(false);
    setIsVideoRecording(false);
    setVideoBlob(null);
  }

  const handleRetakeVideo = () => {
    setIsReplayVideoDialogOpen(false);
    setIsVideoRecording(false);
    setVideoBlob(null);
    setIsCameraDialogOpen(true);
  }

  const handleAddVideo = () => {
    setIsReplayVideoDialogOpen(false);
    if (videoBlob && props?.request?.selectedImages?.length < 5) {
      backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
      let file_id=Math.random().toString(16).slice(2)
      const videoData = {
        id:file_id,
        url: URL.createObjectURL(videoBlob),
        is_active: true,
        blob: videoBlob,
        is_loading:true,
        file_meta: {
          name: "Recorded Video",
          size: videoBlob?.size,
          type: videoBlob?.type,
          file_type: videoBlob?.type?.split("/")?.[1],
        }
      };
      if (props.request.selectedImages.concat([videoData])?.length <= 5) {
        props.request.selectedImages =
          props.request.selectedImages.concat([videoData]);
        uploadImageS3([videoBlob], 50,file_id)

        props.setRequest(props.request);
        backdrop.setBackDrop({ ...backdrop, open: false, message: "Loading..." })
        URL.revokeObjectURL(videoBlob);
      }
      else {
        backdrop.setBackDrop({ ...backdrop, open: false, message: "Loading..." })
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("You can't upload more than 5 media"),
        });
      }
    }
    else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("5 / 5 media are Uploaded , limit reached"),
      });
    }
  }

  const handleAudioRecorderDialogOpen = () => {
    setIsAudioRecorderDialogOpen(true);
    setIsRecordOptionDialogOpen(false);
  }

  const handleAudioRecorderDialogClose = () => {
    setIsAudioRecorderDialogOpen(false);
    setIsAudioRecording(false);
    stopAudioRecorder({ audioRef, mediaRecorderRef: audioRecorderRef });
  }

  const handleReplayAudioDialogClose = () => {
    setIsReplayAudioDialogOpen(false);
    setIsAudioRecording(false);
    setAudioBlob(null);
  }

  const handleRetakeAudio = () => {
    setIsReplayAudioDialogOpen(false);
    setIsAudioRecording(false);
    setAudioBlob(null);
    setIsAudioRecorderDialogOpen(true);
  }

  const handleAddAudio = () => {
    setIsReplayAudioDialogOpen(false);
    if (audioBlob && props?.request?.selectedImages?.length < 5) {
      backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
      let file_id=Math.random().toString(16).slice(2)
      const audioData = {
        id:file_id,
        url: URL.createObjectURL(audioBlob),
        is_active: true,
        is_loading:true,
        file_meta: {
          name: "Recorded Audio",
          size: audioBlob?.size,
          type: audioBlob?.type,
          file_type: audioBlob?.type?.split("/")?.[1],
        }
      };
      if (props.request.selectedImages.concat([audioData])?.length <= 5) {
        props.request.selectedImages =
          props.request.selectedImages.concat([audioData]);
          uploadImageS3([audioBlob],allowed_file_size,file_id)

        props.setRequest(props.request);
        backdrop.setBackDrop({ ...backdrop, open: false, message: "Loading..." })
        URL.revokeObjectURL(audioBlob);
      }
      else {
        backdrop.setBackDrop({ ...backdrop, open: false, message: "Loading..." })
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("You can't upload more than 5 media"),
        });
      }
    }
    else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("5 / 5 media are Uploaded , limit reached"),
      });
    }
  }

  return (
    <div>
      <Container maxWidth="sm" className={classes.root}>
        {/* <TitleBar

          text={t("NewMaintenanceRequest")}
          goBack={goBack}
        /> */}
        <Box className={classes.content}>
          <Box p={2}>
            <Stack direction="row" alignItems="center">
              <Box style={{ marginInlineEnd: "8px" }}><UploadAssetIcon /></Box>
              <Box>
                <Typography className={classes.serviceTitle}>{t("Upload Images & Videos")}</Typography>
                <Typography className={classes.steptitle}>{t("STEP")} {props?.step} / 4</Typography>
              </Box>
            </Stack>
          </Box>
          <Box>
            <Divider />
          </Box>
          <Box p={2}>
            <Grid container spacing={"16px"}>
              <Grid item xs={size?.width > 300 ? 6 : 12}>
                <Upload
                  onChange={handleUpload}
                  single={props?.single}
                  state={props?.images}
                  // disabled={props?.request?.selectedImages?.length>=5?true:false}
                  btnText={t("Upload Images Here")}
                  backgroundColor="#F1F7FF"
                  padding={0}
                />
              </Grid>
              <Grid item xs={size?.width > 300 ? 6 : 12}>
                <Box className={classes.videoUploadBox} alignContent={"center"}
                  onClick={() => setIsRecordOptionDialogOpen(true)}>
                  <Stack rowGap={"18px"} alignItems={"center"} justifyContent={"center"}>
                    <RadioButtonCheckedIcon fontSize="large" htmlColor="#5078e1" />
                    <Stack alignItems={"center"} justifyContent={"center"}>
                      <Typography sx={{
                        color: "#404E61",
                        fontSize: "0.75rem",
                        fontFamily: FontFamilySwitch().semiBold,
                      }}>
                        {t("Record")}
                      </Typography>
                      <Typography sx={{
                        color: "#404E61",
                        fontSize: "0.75rem",
                        fontFamily: FontFamilySwitch().semiBold,
                      }}>
                        {t("Audio/Video")}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            {props?.request?.selectedImages?.length > 0 && (

              <div>
                <Box mt={2} display="flex" justifyContent={"space-between"}>
                  <Typography
                    style={{
                      color: "#404E61",
                      fontSize: "1rem",
                      fontFamily: FontFamilySwitch().semiBold,
                    }}
                  >
                    {t("Uploaded")} {props?.request?.selectedImages?.length} / 5 {t("images")}
                  </Typography>
                  <Typography
                    onClick={() => props?.onRemoveAll()}
                    style={{
                      cursor: "pointer",
                      color: "#5078E1",
                      fontSize: "0.875rem",
                      fontFamily: FontFamilySwitch().semiBold,
                    }}
                  >
                    {t("RemoveAll")}
                  </Typography>
                </Box>
                <Box mt={1.5} style={{ maxHeight: size?.height - 490, overflow: "scroll" }}>
                  {props?.request?.selectedImages?.map((item, i) => {
                    return (
                      <>
                        <Box display="flex" justifyContent={"space-between"} alignItems="center">
                          <Stack direction="row" alignItems="center">
                            <Box style={{ marginInlineEnd: "8px" }}>
                              {item?.file_meta?.file_type === "mp4"
                                ? (item?.blob
                                  ? <VideoCard
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "6px",
                                      border: "1px solid #E4E8EE",
                                      boxShadow: "0px 3px 6px #00000014"
                                    }}
                                    muted={true}
                                    videoBlob={item?.blob} />
                                  : <Box />)
                                : item?.file_meta?.file_type === "mpeg"
                                  ? (item?.url
                                    ? <Box width={"40px"} height={"40px"}><MicIcon fontSize="large" htmlColor="#5078e1" /></Box>
                                    : <Box />)
                                  : <img
                                    src={generateImageUrl(item?.url, img_size.small_rectangle) ?? ""}
                                    alt="upload"
                                    className={classes.uploadedImg}
                                  />}
                            </Box>
                            <Box>
                              <Typography className={classes.imgName} noWrap>{item?.file_meta?.name}</Typography>
                              <Typography className={classes.productDetailsTitle}>{item?.file_meta?.size}{item?.file_meta?.type}</Typography>
                            </Box>
                          </Stack>
                          {item?.is_loading ?
                            <CircularProgress />
                            :
                            <Box className={classes.deleteIcon} onClick={() => props?.handleDelete(item, i)}><DeleteAssetIcon /></Box>
                          }                          
                        </Box>
                        {props?.request?.selectedImages?.length - 1 !== i &&
                          <Box mt={2} mb={2}><Divider /></Box>
                        }
                      </>
                    )
                  })}
                </Box>
              </div>
            )}
          </Box>
        </Box>
        <Container maxWidth="sm" className={classes.btnRoot}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                className={classes.btnPrevious}
                onClick={() => {
                  props.previous(2);
                }}
              >
                {t("Previous")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                className={classes.btn}
                disabled={props?.request?.selectedImages?.length>0?(props?.request?.selectedImages?.filter((z)=>{return z?.is_loading})?.every((x)=>{return x===true})?false:true):false}
                onClick={() => {
                  props.next(4);
                }}
              >
                {t("Next")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <DialogDrawer
        header={t("Record")}
        open={isRecordOptionDialogOpen}
        onClose={() => setIsRecordOptionDialogOpen(false)}
        padding={0}
        component={
          <Box p={2}>
            <Grid container spacing={"16px"}>
              <Grid item xs={size?.width > 300 ? 6 : 12}>
                <Box className={classes.videoUploadBox} alignContent={"center"}
                  onClick={handleAudioRecorderDialogOpen}>
                  <Stack rowGap={"18px"} alignItems={"center"} justifyContent={"center"}>
                    <MicIcon fontSize="large" htmlColor="#5078e1" />
                    <Typography sx={{
                      color: "#404E61",
                      fontSize: "0.75rem",
                      fontFamily: FontFamilySwitch().semiBold,
                    }}>
                      {t("Record Audio")}
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={size?.width > 300 ? 6 : 12}>
                <Box className={classes.videoUploadBox} alignContent={"center"}
                  onClick={handleCameraDialogOpen}>
                  <Stack rowGap={"18px"} alignItems={"center"} justifyContent={"center"}>
                    <VideocamIcon fontSize="large" htmlColor="#5078e1" />
                    <Typography sx={{
                      color: "#404E61",
                      fontSize: "0.75rem",
                      fontFamily: FontFamilySwitch().semiBold,
                    }}>
                      {t("Record Video")}
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
        }
      />
      <DialogDrawer
        header={t("Record Video")}
        open={isCameraDialogOpen}
        onClose={handleCameraDialogClose}
        padding={0}
        component={
          <CamRecorder
            t={t}
            classes={classes}
            videoRef={videoRef}
            mediaRecorderRef={videoRecorderRef}
            recording={isVideoRecording}
            setRecording={setIsVideoRecording}
            setVideoBlob={setVideoBlob}
            setIsCameraDialogOpen={setIsCameraDialogOpen}
            setIsReplayVideoDialogOpen={setIsReplayVideoDialogOpen}
            handleCameraDialogClose={handleCameraDialogClose}
          />
        }
      />
      <DialogDrawer
        header={t("Record Video")}
        open={isReplayVideoDialogOpen}
        onClose={handleReplayVideoDialogClose}
        padding={0}
        component={
          <ReplayVideo
            t={t}
            classes={classes}
            videoBlob={videoBlob}
            handleRetakeVideo={handleRetakeVideo}
            handleReplayVideoDialogClose={handleReplayVideoDialogClose}
            handleAddVideo={handleAddVideo}
          />
        }
      />
      <DialogDrawer
        header={t("Record Audio")}
        open={isAudioRecorderDialogOpen}
        onClose={handleAudioRecorderDialogClose}
        padding={0}
        component={
          <AudioRecorder
            t={t}
            classes={classes}
            audioRef={audioRef}
            mediaRecorderRef={audioRecorderRef}
            setAudioBlob={setAudioBlob}
            recording={isAudioRecording}
            setRecording={setIsAudioRecording}
            handleAudioRecordingDialogClose={handleAudioRecorderDialogClose}
            setIsAudioRecordDialogOpen={setIsAudioRecorderDialogOpen}
            setIsReplayAudioDialogOpen={setIsReplayAudioDialogOpen}
          />
        }
      />
      <DialogDrawer
        header={t("Record Audio")}
        open={isReplayAudioDialogOpen}
        onClose={handleReplayAudioDialogClose}
        padding={0}
        component={
          <ReplayAudio
            t={t}
            classes={classes}
            audioBlob={audioBlob}
            handleRetakeAudio={handleRetakeAudio}
            handleReplayAudioDialogClose={handleReplayAudioDialogClose}
            handleAddAudio={handleAddAudio}
          />
        }
      />
    </div>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("maintance")(Step3), props)
